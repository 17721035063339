<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="notification-view-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base">
            {{ i18n('notification.view.title') }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
          <div class="col-span-12 sm:col-span-12">
            <div
              class="flex justify-center font-medium whitespace-nowrap text-gray-600"
            >
              <div>
                <div
                  class="w-32 h-32 flex-none image-fit rounded-md overflow-hidden"
                >
                  <img
                    :src="record.image"
                    v-if="record && record.image"
                    data-action="zoom"
                  />
                  <img :src="require(`@/assets/images/profile-1.jpg`)" v-else />
                </div>
                <div class="text-center mt-2">
                  {{ i18n('notification.fields.image') }}
                </div>
              </div>
            </div>
            <div class="text-xl font-medium leading-none mt-5">
              {{ i18n('notification.fields.title') }}
            </div>
            <div class="mt-4">
              {{ presenterLocalization(record, 'title') }}
            </div>
            <div class="text-xl font-medium leading-none mt-5">
              {{ i18n('notification.fields.body') }}
            </div>
            <div class="mt-5">
              {{ presenterLocalization(record, 'body') }}
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20"
            @click="$emit('close')"
          >
            <app-i18n code="common.close"></app-i18n>
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterNotificationContent(row, fieldName, key) {
      if (!row) return 'ـــ'
      const val = row[fieldName]
      return key == 'image'
        ? this.presenter(val, key)
        : this.presenterLocalization(val, key)
    }
  }
})
</script>

<style>
.zoom-img-wrap {
  z-index: 99999;
}
</style>
