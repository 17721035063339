<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="notification.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn bg-theme-31 text-white w-1/2 sm:w-auto mr-2 ml-2"
            @click="$router.push('/notification/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>

      <div class="overflow-x-auto scrollbar-hidden">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#000"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              id="notificationTable"
              ref="tableRef"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10" id="ignore-6">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap w-20"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                  <td class="w-10">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap cursor-pointer">
                      {{ presenterLocalization(row, 'title') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ truncateString(presenterLocalization(row, 'body')) }}
                    </div>
                  </td>
                  <!-- <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'type') }}
                    </div>
                  </td> -->
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <a
                        data-toggle="modal"
                        data-target="#notification-view-modal-preview"
                        href="javascript:;"
                        class="text-theme-10 dark:text-theme-30 w-20"
                        @click="doOpenModal(row)"
                      >
                        <EyeIcon
                          class="w-4 h-4"
                          :class="isRTL ? 'ml-2' : 'mr-2'"
                        />
                        <app-i18n code="common.view"></app-i18n>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.notification')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Modal -->
    <NotificationViewModal :record="selectedRow" @close="doCloseModal" />
    <!-- END: Modal -->
  </div>
</template>

<script>
import NotificationViewModal from '@/views/notification/notification-view-modal.vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
// import dayjs from 'dayjs'

export default defineComponent({
  components: {
    NotificationViewModal
  },
  setup() {
    const showModal = () => {
      cash('#notification-view-modal-preview').modal('show')
    }

    const hideModal = () => {
      cash('#notification-view-modal-preview').modal('hide')
    }

    return {
      showModal,
      hideModal
    }
  },
  data() {
    return {
      selectedRow: null,
      visibleModal: false,
      columns: [
        {
          name: 'title',
          field: 'data',
          label: 'notification.fields.title',
          align: 'center'
          // required: true,
        },
        {
          name: 'body',
          field: 'data',
          label: 'notification.fields.body',
          align: 'center'
          // required: true,
        },
        // {
        //   name: 'type',
        //   field: 'type',
        //   label: 'notification.fields.recipients',
        //   align: 'center'
        //   // required: true,
        // },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'notification.fields.createdAt',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'notification/list/rows',
      pagination: 'notification/list/pagination',
      loading: 'notification/list/loading'
    }),
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'notification/list/doFetch',
      doFetchNextPage: 'notification/list/doFetchNextPage',
      doFetchPreviousPage: 'notification/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'notification/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'notification/list/doChangePaginationPageSize'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doOpenModal(row) {
      this.selectedRow = row
      this.visibleModal = true
      // this.showModal()
    },
    doCloseModal() {
      // this.hideModal()
      this.selectedRow = null
      this.visibleModal = false
    },
    truncateString(str) {
      return str.length > 100 ? str.slice(0, 100) + '...' : str
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
